import React from "react";
import Modal from 'react-bootstrap/Modal'
import { FormattedMessage } from "react-intl";

class ModalFormFragment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customHeaderText: null
        }
    }

    render() {
        return (
            <Modal {...this.props} className={this.props.is_delete ? 'delete-modal' : ''}>
                <Modal.Header closeButton>
                    <FormattedMessage id={this.state.customHeaderText != null ? this.state.customHeaderText : this.props.title_id}>
                        {(value) => <Modal.Title className="text-left">{value}</Modal.Title>}
                    </FormattedMessage>
                </Modal.Header>

                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalFormFragment;