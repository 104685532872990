export default class DragDrop {
    static startDrag(event, index, parent = null) {
        event.dataTransfer.dropEffect = "move";
        event.dataTransfer.effectAllowed = "move";

        if (parent) {
            parent.setState({
                helper_drag_index: index
            })
        }
    }

    static dragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    static drop(event, drop_index, parent, array, state_item, state_to_update = null) {
        let drag_index = parent.state.helper_drag_index;
        let new_array = [];

        array.forEach((item, index) => {
            if (index === drag_index) {
                item.index = drop_index;
            } else if (index >= drop_index && index < drag_index) {
                item.index = index + 1;
            } else {
                item.index = index;
            }

            new_array[item.index] = item;
        })

        if (state_to_update != null) {
            let state_items = state_item.split('.');

            if (state_items.length > 1) {
                let parent_item = state_to_update.state[state_items[0]]
                parent_item[state_items[1]][state_items[2]] = new_array;
                state_to_update.setState({
                    [state_item[0]]: parent_item
                }, () => {
                    if (parent.submit) {
                        parent.submit();
                    }
                })
            }
        } else {
            parent.setState({ [state_item]: new_array })
        }
    }

    static updateNestedObject(items, updated_value, item = null, index = 0) {
        item = items[index];
        this.updateNestedObject(items, updated_value, item, index + 1)
    }
}
